<template>
  <div>
    <b-button v-if="quoteSelected.paymentIntent.claimable" id="order-view-action-claim-btn" v-ripple.400 :variant="variant || 'warning'" class="mb-75" block @click.prevent="claimEdition = true">
      <feather-icon :icon="btnIcon || 'AlertTriangleIcon'" size="20" class="align-middle" />
      <span class="ml-25 align-middle"> {{ btnText || $t('action.claim') }} </span>
    </b-button>

    <app-sidebar-form
      form-name="order-view-action-claim"
      :edition="claimEdition"
      edition-mode-title="edit"
      :disabled="filesLoading"
      :title="claimTitle || $t('action.claim')"
      :entity="claim"
      :submit-btn-text="btnText || $t('action.claim')"
      :submit-btn-icon="btnIcon || 'AlertTriangleIcon'"
      :submit-btn-variant="variant || 'warning'"
      @update:edition="claimEdition = $event"
      @update:entity="claimQuote($event)"
    >
      <template #fields="data">
        <b-row>
          <b-col cols="12">
            <!-- nature-->
            <validation-provider
              #default="{ errors }"
              :name="$t('offer.max_subscription')"
              :rules="!isQuoteOwner ? 'required' : ''"
              vid="order-view-action-claim-nature-provider"
            >
              <b-form-group :label="$t('common.nature')" label-for="order-view-action-claim-nature" :class="{ 'validation-required': !isQuoteOwner }">
                <b-form-input
                  id="order-view-action-claim-nature"
                  v-model="data.item.nature"
                  type="text"
                  :placeholder="$t('common.nature')"
                  :state="errors[0] ? false : null"
                  :plaintext="isQuoteOwner"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <!-- comment -->
            <validation-provider #default="{ errors }" :name="$t('common.comment')" rules="" vid="order-view-action-claim-comment-provider">
              <b-form-group :label="$t('common.comment')" label-for="order-view-action-claim-comment">
                <b-form-textarea
                  id="order-view-action-claim-comment"
                  v-model="data.item.comment"
                  :placeholder="$t('common.comment')"
                  no-resize
                  :rows="!isQuoteOwner ? 4 : 0"
                  class="hide-scroll"
                  max-rows="4"
                  :state="errors[0] ? false : null"
                  :plaintext="isQuoteOwner"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- files -->
          <b-col cols="12">
            <AppAxiosFormFile v-if="!isQuoteOwner" @updateId="data.item.temporaryFilesIds = $event" @upload-in-progress="filesLoading = $event" />
          </b-col>
          <b-col v-if="isQuoteOwner" cols="12">
            <label class="form-label">{{ $tc('common.evidence', 2) }}</label>
            <b-container fluid>
              <b-row class="mb-2 img-group hide-scroll" align-h="between">
                <b-col v-for="(claimFile, index) in data.item.claimFiles" :key="claimFile.filePath" cols="4" class="mx-n1">
                  <figure class="file-figure m-25">
                    <!-- @error="$event.target.src = '@/assets/images/logo/logo.png'" -->
                    <b-img
                      :src="claimFile.fileUrls[300]"
                      height="100px"
                      :alt="`Claim file ${index}`"
                      rounded
                      @error="imageLoadOnError"
                      @click="openUrl(claimFile.fileUrls.default)"
                    />
                    <figcaption class="font-italic font-weight-light font-small-2 mt-50">{{ claimFile.filePath }}</figcaption>
                  </figure>
                </b-col>
              </b-row>
              <!-- <img src="..." onerror="this.onerror=null; this.src='Default.jpg'" /> -->
            </b-container>
          </b-col>
          <b-col cols="12">
            <!-- refundType -->
            <enum-select
              v-if="isQuoteOwner"
              id="order-view-action-claim-refund-type"
              v-model="data.item.refundType"
              :label="$t('common.refund_type')"
              enum-class="quoteClaimRefundType"
              class="select-size-md"
              :clearable="false"
              :searchable="false"
              required
            />
          </b-col>
          <b-col cols="6">
            <!-- refundAmount -->
            <validation-provider
              v-if="isQuoteOwner && data.item.refundType === 'partial_refund'"
              #default="{ errors }"
              :name="$t('common.refund_amount')"
              vid="order-view-action-claim-refund-amount-provider"
              rules="required"
            >
              <b-form-group :label="$t('common.refund_amount')" label-for="order-view-action-claim-refund-amount-group" class="validation-required">
                <b-input-group :append="currency">
                  <b-form-input
                    id="order-view-action-claim-refund-amount"
                    v-model="data.item.refundAmount"
                    :placeholder="$t('common.refund_amount')"
                    :state="errors[0] && false"
                    :formatter="numberFormat"
                  />
                </b-input-group>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <!-- handleClaimComment -->
            <validation-provider
              v-if="isQuoteOwner"
              #default="{ errors }"
              :name="$t('common.refund_comment')"
              rules="required"
              vid="order-view-action-claim-comment-provider"
            >
              <b-form-group :label="$t('common.refund_comment')" label-for="order-view-action-claim-comment" class="validation-required">
                <b-form-textarea
                  id="order-view-action-claim-comment"
                  v-model="handleClaimComment"
                  :placeholder="$t('common.refund_comment')"
                  no-resize
                  rows="4"
                  class="hide-scroll"
                  max-rows="4"
                  :state="errors[0] ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </template>
    </app-sidebar-form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

import { patchQuoteClaimRequest, postQuoteClaimRequest } from '@/request/globalApi/requests/quoteRequests'

import AppSidebarForm from '@/components/AppSidebarForm.vue'
import AppAxiosFormFile from '@/components/AppAxiosFormFile.vue'

export default {
  name: 'OrderViewActionClaim',

  components: { AppSidebarForm, AppAxiosFormFile },

  props: {
    btnText: {
      type: String,
      default: '',
    },

    btnIcon: {
      type: String,
      default: '',
    },

    claimTitle: {
      type: String,
      default: '',
    },

    variant: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      claimEdition: false,
      handleClaimComment: '',
      filesLoading: false,
    }
  },

  computed: {
    ...mapState('quote', ['quoteSelected', 'isQuoteOwner']),
    ...mapState('appConfig', ['currency']),
    ...mapFields('quote', ['quoteLoading']),

    claim() {
      if (this.isQuoteOwner) {
        return this.quoteSelected.quote.claim
      }
      return { nature: '', comment: '', temporaryFilesIds: [] }
    },
  },

  methods: {
    ...mapActions('quote', ['fetchQuote']),

    imageLoadOnError(e) {
      e.target.src = require('@/assets/images/icons/file-icons/doc2.png')
    },

    claimQuote(claim) {
      this.quoteLoading = true
      if (this.isQuoteOwner) {
        return patchQuoteClaimRequest(
          this.quoteSelected.quote.id,
          {
            refundType: claim.refundType,
            comment: this.handleClaimComment,
            refundAmount: claim.refundAmount,
          },
          this.$store.getters['quote/quoteTitleAndRef'],
        )
          .then(() => {
            this.fetchQuote({ refresh: true, id: this.quoteSelected.quote.id }).finally(() => {
              this.quoteLoading = false
            })
          })
          .catch(err => {
            err && (this.quoteLoading = false)
          })
      }

      return postQuoteClaimRequest(this.quoteSelected.quote.id, { ...claim }, this.$store.getters['quote/quoteTitleAndRef'], this.$t('request.action.claim'))
        .then(() => {
          this.fetchQuote({ refresh: true, id: this.quoteSelected.quote.id }).finally(() => {
            this.quoteLoading = false
          })
        })
        .catch(err => {
          err && (this.quoteLoading = false)
        })
    },

    openUrl(url) {
      window.open(url, '_blank')
    },

    numberFormat: value => value.replace(/,/g, '.'),
  },
}
</script>
<style lang="scss" scoped>
.form-label {
  color: #5e5873;
  font-size: 0.857rem;
}
.img-group {
  max-height: 300px;
  overflow: auto;
}

.file-figure {
  width: inherit;
  text-overflow: ellipsis;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}
</style>
